import React, { useState, useCallback } from 'react';
import { Input, Icon } from 'semantic-ui-react';

const PasswordInput = ({ name, value, onChange, ...props }) => {
    const [isVisible, setVisible] = useState(false);

    const toggleVisibility = useCallback(() => {
        setVisible(prev => !prev)
    }, [])

    return (
        <Input
            {...props}
            icon={isVisible ? (
                <Icon 
                    name="eye"
                    link
                    onClick={toggleVisibility}
                />
            ) : (
                <Icon 
                    name="eye slash"
                    link
                    onClick={toggleVisibility} 
                />
            )}
            iconPosition="right"
            name={name}
            value={value}
            type={isVisible ? 'text' : 'password'}
            onChange={onChange}
        />
    )
}

export default PasswordInput