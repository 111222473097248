export const LOGIN_LINK = '/login';
export const ROLES_LINK = '/roles';
export const ROLE_LINK = '/roles/:id';
export const NEW_ROLE_LINK = '/roles/new';
export const USERS_LINK = '/users';
export const USER_LINK = '/users/:id';
export const NEW_USER_LINK = '/users/new';
export const GRID_LIST_LINK = '/grid/:name';
export const GRID_CARD_LINK = '/grid/:name/:id';
export const GRID_GRID_CARD_LINK = '/grid/:parentName/:name/:id';
export const GRID_NEW_LINK = '/grid/:name/new';
export const DICTIONARY_LIST_LINK = '/dictionary/:name';
export const DICTIONARY_CARD_LINK = '/dictionary/:name/:id';
export const DICTIONARY_NEW_LINK = '/dictionary/:name/new';
export const FIELDS_SETTING_LINK = '/fields_setting';
export const REPORTS_LINK = '/reports';
export const OPEN_REPORTS_LINK = '/open/reports';
export const SIGN_WITHOUT_LOGIN = `/signwithoutlogin/:userId/:token`;
export const AUTO_LOGIN_DEMO = `/demo-login`;

export const customPage = [ROLES_LINK, USERS_LINK, FIELDS_SETTING_LINK];
