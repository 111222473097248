import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportPowerBi from 'powerbi-report-component';
import { getOpenReportRequest, openReportSelector } from '../../ducks/openReports';
import EmptyHeader from '../../components/Header/emptyHeader';

const OpenReport = () => {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    let [isMobile, setIsMobile] = useState(false);

    const report = useSelector(state => openReportSelector(state));

    useEffect(() => {
        dispatch(getOpenReportRequest());
    }, []);

    useEffect(
        () => {
            if (containerRef && containerRef.current && containerRef.current.offsetWidth <= 990) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        },
        [containerRef, containerRef.current],
    );

    console.log('isMobile', isMobile);

    return (
        <>
            <EmptyHeader />
            <div className="report-container" ref={containerRef}>
                <ReportPowerBi
                    embedType="report"
                    tokenType="Embed"
                    accessToken={report.token}
                    embedUrl={report.embedURL}
                    embedId={report.reportID}
                    dashboardId=""
                    pageName={isMobile ? report.pageName : ''}
                    extraSettings={{
                        filterPaneEnabled: !isMobile,
                        navContentPaneEnabled: !isMobile,
                        layoutType: isMobile ? 2 : 0,
                    }}
                    permissions="All"
                    style={{
                        height: 'calc(100vh - 40px)',
                        width: '100%',
                        border: '0',
                        background: '#eee',
                    }}
                    onLoad={report => {}}
                    onSelectData={data => {
                            console.log("ReportPowerBi-onSelectData");
                            console.log(data);
                        }
                    }
                    onPageChange={data => {
                            console.log("ReportPowerBi-onPageChange");
                            console.log(data);
                        }
                    }
                    onTileClicked={data => {
                            console.log("ReportPowerBi-onTileClicked");
                            console.log(data);
                        }
                    }
                />
            </div>
        </>
    );
};

export default OpenReport;