import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Dimmer, Form, Input, Loader, Icon } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    errorSelector,
    getLoginPageRequest,
    isAuthSelector,
    loginPageSelector,
    loginRequest,
    progressSelector,
} from '../../ducks/login';
import PasswordInput from './components/PasswordInput';
import playCircleIcon from '../../icons/svg/play-circle.svg';
import loginChartsIcon from '../../icons/svg/login-charts.svg';
import './style.scss';

const Login = ({ isAutoLogin = false }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(state => loginPageSelector(state)) || {};
    const isAuth = useSelector(state => isAuthSelector(state));
    const error = useSelector(state => errorSelector(state));
    const loginProgress = useSelector(state => progressSelector(state, 'login_progress'));
    const pageLoadingProgress = useSelector(state => progressSelector(state, 'page_progress'));
    const { form: formElements = {} } = page;
    const { inputs = [], login_btn = {} } = formElements;

    const [form, setForm] = useState({});

    const loginPageRef = useRef(null)

    useEffect(() => {
        dispatch(getLoginPageRequest());
    }, []);

    const calculateCSSVarible = useCallback(() => {
        if (loginPageRef.current && loginPageRef.current.querySelector('.login-form__textfield')) {
            loginPageRef.current.style.setProperty(
                '--form-height',
                `${loginPageRef.current.querySelector('.login-charts-wrapper').clientHeight}px`
            )
        } else {
            setTimeout(calculateCSSVarible, 100)
        }
    }, [loginPageRef])

    const resizeObserver = useMemo(() => (
        new ResizeObserver(calculateCSSVarible)
    ), [loginPageRef])

    useEffect(() => {
        if (loginPageRef.current) {
            document.body.style.overflowY = 'auto'
            calculateCSSVarible()
            resizeObserver.observe(loginPageRef.current.querySelector('.login-charts-wrapper'))
            return () => {
                resizeObserver.disconnect()
            }
        }
    }, [loginPageRef])

    const handleChange = (e, { name, value }) => {
        const values = {
            ...form,
            [name]: value,
        };
        setForm(values);
    };

    const handleFormSubmit = (e, { api }) => {
        const params = {
            form: {
                ...form,
                language: 'ru',
            },
            api: login_btn.api,
        };
        dispatch(loginRequest(params));
    };

    const handleDemoLogin = () => {
        const params = {
            form: {
                login: 'LTL-RU@pooling.me',
                password: 'Pass1234',
                language: 'ru',
            },
            api: login_btn.api,
        };
        dispatch(loginRequest(params));
    };

    useEffect(() => {
        if (isAutoLogin && !isAuth) {
            handleDemoLogin();
        }
    }, [isAutoLogin, isAuth]);

    if (isAutoLogin && isAuth) {
        return <Redirect to="/" />;
    }

    if (isAutoLogin && !isAuth) {
        return null;
    }

    return (
        <>
            <Dimmer inverted active={pageLoadingProgress}>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            {!isAuth ? (
                <>
                    <div ref={loginPageRef} className="login-page">
                        <img className="login-page__logo" src="/PoolingPlusWhite.svg" alt="Pooling Logo" />
                        <h1 className="login-page__title">Аналитика</h1>
                        <div className="login-charts-wrapper">
                            <Form className="login-form" onSubmit={handleFormSubmit}>
                                <p className="login-form__title">Доступ к полной аналитике</p>
                                <ul className="login-form__list">
                                    <li>Контроль и прогнозирование транспортных расходов</li>
                                    <li>Рекомендации по оптимизации транспортных расходов</li>
                                    <li>Контроль текущей ситуации с отгрузками</li>
                                </ul>
                                {inputs.map(input => (
                                    <div key={input.name} className="login-form__textfield">
                                        <label
                                            className="login-form__label"
                                            htmlFor={input.name}
                                        >
                                            {t(input.name)}
                                        </label>
                                        {input.name === 'password' ? (
                                            <PasswordInput
                                                id={input.name}
                                                className="login-form__input"
                                                name={input.name}
                                                value={form[input.name]}
                                                onChange={handleChange}
                                            />
                                        ) : (
                                            <Input
                                                id={input.name}
                                                className="login-form__input"
                                                name={input.name}
                                                value={form[input.name]}
                                                type={input.type}
                                                onChange={handleChange}
                                            />
                                        )}
                                    </div>
                                ))}
                                <a
                                    className="login-form__recovery"
                                    href="/"
                                >
                                    Забыли пароль?
                                </a>
                                <Button
                                    className="login-button login-form__button"
                                    api={login_btn.api}
                                    loading={loginProgress}
                                >
                                    {t(login_btn.name)}
                                </Button>
                                <a className="login-form__access" href="https://b24-rumxix.bitrix24site.ru/crm_form_wowxn/" target="_blank">
                                    <Button className="login-button login-button_outlined" type="button">
                                        Запросить доступ
                                    </Button>
                                </a>
                                <a className="login-form__video" href="https://views.paperflite.com/assets/63f344ed4245e728a3a99f22?utm_source=Paperflite%20Link" target="_blank">
                                    <Button className="login-button login-button_simple" type="button">
                                        <img
                                            className="login-button__icon"
                                            src={playCircleIcon}
                                            alt=""
                                            role="presentation"
                                        />
                                        Смотреть видеоинструкцию
                                    </Button>
                                </a>
                                {error && (
                                    <p className="login-form__error">
                                        <Icon
                                            className="login-form__error_icon"
                                            name="info circle"
                                        />

                                        {t(error)}
                                    </p>
                                )}
                            </Form>
                            <div className="tariffs-schedules-block">
                                <p className="tariffs-schedules-block__title">графики по всем складам доставка</p>
                                    <ul className="tariffs-schedules-block__list">
                                        <li>Открытый доступ к информации о тарифах и графиках по всем складам России</li>
                                        <li>Анализ полноты графиков и тарифов по складам</li>
                                        <li>Учет складов перевозчиков, которые осуществляют сборную доставку на склады клиентов</li>
                                    </ul>
                                <Button
                                    type="button"
                                    className="login-button tariffs-schedules-block__button"
                                    loading={loginProgress}
                                    onClick={handleDemoLogin}
                                >
                                    Склады, графики и тарифы
                                </Button>
                                <a className="tariffs-schedules-block__video" href="https://views.paperflite.com/assets/65c1e3321dffeb5935bde2bc?utm_source=Paperflite%20Link" target="_blank">
                                    <Button className="login-button login-button_simple" type="button">
                                        <img
                                            className="login-button__icon"
                                            src={playCircleIcon}
                                            alt=""
                                            role="presentation"
                                        />
                                        Смотреть видеоинструкцию
                                    </Button>
                                </a>
                                <div className="tariffs-schedules-block__block"/>
                            </div>
                        </div>
                        <p className="login-page__phone-text">
                            <span>Бесплатная горячая линия: </span>
                            <a className="login-page__link" href="tel:+78007002805">8 (800) 700 28 05</a>
                        </p>
                        <a className="login-page__link login-page__link_underline" href="mailto:support@artlogics.ru">support@artlogics.ru</a>
                        <img className="login-page__artlogic-logo" src="/artlogic-logo.svg" alt="artlogic" />
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Login;
