import React, { useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import './style.scss';
import { OPEN_REPORTS_LINK } from '../../router/links';

const EmptyHeader = () => {
    const { location } = useReactRouter();

    let [activeItem, setActiveItem] = useState(location.pathname);

    useEffect(
        () => {
            setActiveItem(location.pathname);
        },
        [location.pathname],
    );

    return (
        <header>
            <Menu pointing secondary fixed="top" style={{ paddingLeft: '12px' }}>
                <Menu.Item 
                    className="header-inner-logo" 
                    key={'logo'}
                    >
                    <img src={'/PoolingPlusWhite.svg'} alt={'LOGO'} />
                    
                </Menu.Item>
                    <Menu.Item 
                    className="large" 
                    key={'report'}
                    as={Link}
                    to={OPEN_REPORTS_LINK}
                    active={activeItem.includes('report') || activeItem == '/'}
                    >
                    Аналитика
                    
                </Menu.Item>
                <div className="header-support">
                    <div className="header-support_contacts">
                        <a href="mailto:info@pooling.me">info@pooling.me</a>
                    </div>
                    <div className="header-support_contacts">
                        <a href="tel:88007002805">8&nbsp;800&nbsp;700&nbsp;28&nbsp;05</a>
                    </div>
                    <div className="header-support_contacts">
                        <a href="tel:84996474213">8&nbsp;499&nbsp;647&nbsp;42&nbsp;13</a>
                    </div>
                </div>
            </Menu>
        </header>
    );
};
EmptyHeader.propTypes = {};

export default EmptyHeader;
