import React from "react";
import { Grid, Header } from "semantic-ui-react";
import './style.scss';

const InstructionContent = (pathName) => {
    const content = new Object();

    content["/"] = {
        title: "Добро пожаловать в Pooling plus!",
        content:
            <div className="instruction">
                <Header as='h2'>Отслеживайте показатели</Header>

                <Grid columns={2}>
                    <Grid.Column>
                        <ol>
                            <li><p>Работайте с интерактивным отчётом. Используйте фильтры дат, чтобы отследить нужный период.</p></li>
                            <li><p>Перемащайтесь между разделами отчёта используя нижнее меню.</p></li>
                            <li><p>Используйте <b>Pooling Plus</b> в верхнем меню для перехода в отчёты.</p></li>
                        </ol>
                    </Grid.Column>
                    <Grid.Column><img src={"instruction_1.png"}/></Grid.Column>
                </Grid>
            </div>
    };

    content["/reports"] = content["/"];

    /*content["/grid/shippings"] = {
        title: "Перевозки",
        content:
            <label>instruction  for /grid/shippings</label>
    };*/

    const res = content[pathName]
    
    return res;
}

export default InstructionContent;