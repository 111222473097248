import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { postman } from '../utils/postman';

//*  TYPES  *//
const GET_OPEN_REPORT_REQUEST = 'GET_OPEN_REPORT_REQUEST';
const GET_OPEN_REPORT_SUCCESS = 'GET_OPEN_REPORT_SUCCESS';
const GET_OPEN_REPORT_ERROR = 'GET_OPEN_REPORT_ERROR';

//*  INITIAL STATE  *//

const initial = {
    data: {},
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_OPEN_REPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_OPEN_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                data: payload,
            };
        case GET_OPEN_REPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getOpenReportRequest = payload => {
    return {
        type: GET_OPEN_REPORT_REQUEST,
        payload
    }
};

//*  SELECTORS *//
const stateSelector = state => state.openReports;
export const openReportSelector = createSelector(stateSelector, state => state.data);
export const openReportProgressSelector = createSelector(stateSelector, state => state.progress);

//*  SAGA  *//

function* getOpenReportSaga({ payload }) {
    try {
        const result = yield postman.get('/report/open/config');

        yield put({
            type: GET_OPEN_REPORT_SUCCESS,
            payload: result
        })
    } catch (e) {
        yield put({
            type: GET_OPEN_REPORT_ERROR
        })
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_OPEN_REPORT_REQUEST, getOpenReportSaga)
    ])
}
